document.addEventListener('turbolinks:load', () => {
   if (!$('body.provider_profiles').length) return;

   $('input#provider_profile_avatar').change(function() {
      const reader = new FileReader();
      reader.onload = (event) => {
         $('img.avatar-preview').attr('src', event.target.result);
      };
      reader.readAsDataURL(this.files[0]);
   });

   window.resetPassword = function (providerId, token, passwordResetModal) {
      if (window.confirm("Are you sure you want to reset this user's password to a temporary password?")) {
         $.post(
             `/en/admin/provider_profiles/${providerId}/reset_password`,
             {token},
             (success) => {
                const $passwordResetModal = $(passwordResetModal);
                $passwordResetModal.find('.new-password').text(success.password);
                $passwordResetModal.modal({show: true});
             })
             .fail(function(error) {
                window.alert('Something went wrong.');
             });
      }
   };
});