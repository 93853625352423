document.addEventListener('turbolinks:load', () => {
    if (!$('body.client_profiles')) return;

    $('input[name="client_profile[referred_by][]"]').on('change', function() {
        const $this = $(this);
        if ($this.val() === 'EnCasa Care Connections Home Health Aide') {
            const $input = $('.form-group.hha_referred_by');
            $input.addClass('hide');
            if ($this.prop('checked')) $input.removeClass('hide');
        }
    });

    $('select[name="client_profile[interview_preference]"]').on('change', function() {
        const $note = $('span.public-space-note');
        if ($(this).val() === 'public_space')
            $note.removeClass('hide');
        else
            $note.addClass('hide');
    });

    function disableOtherChecks() {
        const $this = $(this);
        const $parent = $this.closest('li');
        $parent
            .siblings('li.checkbox-option')
            .find('input')
            .attr({'disabled': $this.prop('checked')})
            .prop('checked', false);
    }

    $('input[name="client_profile[transportation_assistance][]"][value="No transportation required"]')
        .on('change', disableOtherChecks);

    $('input[name="client_profile[meal_assistance_needed][]"][value="No assistance with meals or snacks needed"]')
        .on('change', disableOtherChecks);

    // Prevent accidental form submission with enter key
    $(document).on("keydown", ":input:not(textarea)", function(event) {
        if (event.key == "Enter") {
            event.preventDefault();
        }
    });
})