window.windowHandlersAdded = false;
document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip();

    const table = $('table.data-table').DataTable({
        orderCellsTop: true,
        fixedHeader: true,
        pageLength: 100,
        lengthChange: false,
        dom:'ftp',
        buttons: [ { extend: 'csv', text: 'Download Spreadsheet' } ]
    });
    table.buttons()
        .container()
        .appendTo($('.dataTables_filter'), table.table().container())

    $('table.data-table tbody').on('click', 'tr', function() {
        const href = $(this).data('href');
        if (href) Turbolinks.visit(href);
    });

    document.addEventListener('turbolinks:before-cache', () => table.destroy());

    $('select.show-next-if-yes,select.show-next-if-no').on('change', function() {
        const $this = $(this);
        const $siblings = $this.siblings('.default-hidden');
        if ($siblings.length) {
            $siblings.addClass('hide');
            const showIfYes = $this.hasClass('show-next-if-yes');
            const showIfNo = $this.hasClass('show-next-if-no');
            const yes = $this.val() === 'true';
            const no = $this.val() === 'false';
            if ( (showIfYes && yes) || (showIfNo && no) ) {
                $siblings.removeClass('hide');
                $siblings.filter(function() { return $(this).hasClass('required-if-visible'); })
                         .each(function() { $(this).find('input').attr('required', true); });
            } else {
                $siblings.each(function() { $(this).find('input').attr('required', false); });
            }

        }
    });

    $('select.disable-submit-if-no').on('change', function() {
        const $this = $(this);
        const $form = $this.parents('form');
        const $submit = $form.find('input[type="submit"]');
        const no = $this.val() === 'false';
        $submit.attr('disabled', no);
    });

    $('select.show-other-rows-if-yes').on('change', function() {
        const $this = $(this);
        const $parentRow = $this.parents('.row');
        const $otherRows = $parentRow.siblings('.default-hidden');
        if ($otherRows) {
            $otherRows.addClass('hide');
            const yes = $this.val() === 'true';
            if (yes) $otherRows.removeClass('hide');
        }
    });


    $('.provider-nav .expand-button').click(function(e) {
        e.stopPropagation();
        $(this.closest('.provider-nav')).toggleClass('collapse-xs');
    });


    if (window.windowHandlersAdded) return;

    let formSubmitting = false;
    window.setFormSubmitting = function() { formSubmitting = true; };

    const unsavedChangesHandler = function(e) {
        if (formSubmitting) return undefined;
        if (!$('body').hasClass('edit') || !$('.admin-container')) return undefined;

        var confirmationMessage = 'It looks like you have been editing something. '
            + 'If you leave before saving, your changes will be lost.';

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }

    window.addEventListener("beforeunload", unsavedChangesHandler);
    $(document).on("page:before-change turbolinks:before-visit", function(e) {
        const msg = unsavedChangesHandler(e);
        if (!msg) return;
        return window.confirm(msg);
    });
    window.windowHandlersAdded = true;
});


