document.addEventListener('turbolinks:load', () => {
    if (!$('body.referral_events').length) return;

    window.showDeleteModal = function(event) {
        event.preventDefault();
        event.stopPropagation();
        $('#deleteConfirmModal').modal('show');
    }

    window.deleteReferralEvent = function (id) {
        window.setFormSubmitting();
        $.ajax({
            url: `/en/admin/referral_events/${id}`,
            type: 'DELETE'
        })
        .fail(function(error) {
            console.error(error);
            window.alert('Something went wrong.');
        });

    }

});